@import 'src/assets/styles/variables';

.caret-list {
  li {
    margin-top: 5px;
    list-style: none;
  }
  li:before {
    content: '\203A';
    margin-left: -30px;
    margin-right: 20px;
  }
}

.bracket-list {
  counter-reset: list;
  margin: 0;

  > li {
    margin-top: 15px;
    list-style: none;
    position: relative;
  }

  > li:before {
    counter-increment: list;
    content: counter(list) ") ";
    position: absolute;
    left: -1.4em;
  }
}

.dot-list {
  > li {
    margin-top: 10px;
  }
}

.signature {
  border: 0;
  border-bottom: 1px solid #000;
  margin-left: 20px;
  width: 250px;
  background-color: transparent;
}

.content-wrapper {
  width: 80%;
  margin: 80px auto 0;
}

.new-page {
  height: 80px;
}