//@import 'assets/styles/_variables.scss';
@import 'assets/styles/_mixins.scss';
@import 'assets/styles/_variables.scss';
@import 'assets/fonts.scss';

body {
  margin: 0;
  font-family: 'SKODA Next', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-gray-very-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }

  .content-wrapper {
    p, li {
      font-size: 12px;
    }

    h3 {
      font-size: 14px;
    }

    h1 {
      font-size: 18px;
    }
  }

  .pb-before {
      page-break-before: always !important;
  }
}
